<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-7 pl-7' : 'px-8'">
    <Snackbar ref="snackbar" />

    <div class="mt-5">
      <!-- <Breadcrumbs :title="`Daftar Progress`" :items="itemsBreadcrumbs" /> -->

      <!-- {{ detailPackage }} -->

      <v-btn href="javascript:history.back()"
        class="text-capitalize pl-0"
        elevation="0"
        text
        small
        color="#F05326">
        <v-icon left>mdi-chevron-left</v-icon>
        kembali
      </v-btn>
      <div class="title black--text mb-2">Daftar Progress</div>

      <v-card outlined class="box-shadow border-radius pa-0 pb-1">
        <v-card-title>
          {{ detailUser.name }} | {{ detailUser.email }}
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="#F0532630"
            class="text-capitalize"
            style="color: #F05326"
            small
            @click="handleDelete">
            <v-icon left>mdi-delete-outline</v-icon>
            Hapus
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="3">
              <v-list-item two-line class="px-0 pb-2">
                <v-list-item-content>
                  <v-list-item-subtitle class="caption text-third">
                    Package
                  </v-list-item-subtitle>
                  <v-list-item-title class="body-2 text-capitalize">
                    {{ detailPackage.package.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="3">
              <v-list-item two-line class="px-0 pb-2">
                <v-list-item-content>
                  <v-list-item-subtitle class="caption text-third">
                    Max Point
                  </v-list-item-subtitle>
                  <v-list-item-title class="body-2 text-capitalize">
                    {{ detailPackage.max_point }} point
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="3">
              <v-list-item two-line class="px-0 pb-2">
                <v-list-item-content>
                  <v-list-item-subtitle class="caption text-third">
                    Total Point
                  </v-list-item-subtitle>
                  <v-list-item-title class="body-2 text-capitalize">
                    {{ detailPackage.total_point }} point
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="3">
              <v-list-item two-line class="px-0 pb-2">
                <v-list-item-content>
                  <v-list-item-subtitle class="caption text-third">
                    Terakhir Akses
                  </v-list-item-subtitle>
                  <v-list-item-title class="body-2 text-capitalize">
                    
                    {{ detail_member.last_login | datetime }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="3">
              <v-list-item two-line class="px-0 pb-2">
                <v-list-item-content>
                  <v-list-item-subtitle class="caption text-third">
                    Level
                  </v-list-item-subtitle>
                  <v-list-item-title class="body-2 text-capitalize">
                    {{ detailPackage.package.level }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="3">
              <v-list-item two-line class="px-0 pb-2">
                <v-list-item-content>
                  <v-list-item-subtitle class="caption text-third">
                    Total Pekan diSelesaikan
                  </v-list-item-subtitle>
                  <v-list-item-title class="body-2 text-capitalize">
                    {{ detailPackage.total_week_completed }} pekan
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="3">
              <v-list-item two-line class="px-0 pb-2">
                <v-list-item-content>
                  <v-list-item-subtitle class="caption text-third">
                    Total Aktifitas diSelesaikan
                  </v-list-item-subtitle>
                  <v-list-item-title class="body-2 text-capitalize">
                    {{ detailPackage.total_activity_completed }} aktifitas
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- WEEKS ACTIVITY -->
      <v-col cols="12" class="overflow-auto" v-if="detailPackage.weeks.length > 0">
        <div style="width: fit-content">
          <table class="simple-table align-start" style="width: 100%;">
            <thead>
              <tr>
                <th>Topik</th>
                <th>Poin</th>
                <th>Durasi diSelesaikan</th>
                <th colspan="6">Aktivitas</th>
              </tr>
            </thead>
            
            <tbody class="subtitle-2">
              <template v-for="(item, TopicIndex) in detailPackage.weeks">
                <tr :key="`item-${TopicIndex}`" style="vertical-align: baseline">
                  <td class="py-4" :rowspan="item.activities.length + 1">
                    <div class="mb-1 font-weight-medium">{{ item.topic_name }}</div>
                  </td>
                  <td class="py-4" :rowspan="item.activities.length + 1">
                    <div class="mb-1 font-weight-medium">
                      {{ item.week_point + '/' + item.max_point }}
                    </div>
                  </td>
                  <td class="py-4 body-2" :rowspan="item.activities.length + 1">
                    <div class="mb-1 text--secondary">Completed at:</div>
                    <div class="mb-1 font-weight-medium">{{ item.complete_at | datetime }}</div>
                    <!-- <div class="mb-1 text--secondary">Progress Duration:</div>
                    <div class="mb-1 font-weight-medium">{{ item.complete_duration || '-' }}</div> -->
                  </td>
                  <th class="text--secondary text-caption font-weight-medium" style="border-bottom: white solid 1px">Activity</th>
                  <th class="text--secondary text-caption font-weight-medium" style="border-bottom: white solid 1px">Progress</th>
                  <th class="text--secondary text-caption font-weight-medium" style="border-bottom: white solid 1px">Point</th>
                  <th class="text--secondary text-caption font-weight-medium" style="border-bottom: white solid 1px">Complete At</th>
                  <th class="text--secondary text-caption font-weight-medium" style="border-bottom: white solid 1px">Duration</th>
                  <th class="text--secondary text-caption font-weight-medium" style="border-bottom: white solid 1px">Status</th>
                </tr>
                <tr
                  v-for="(activity, ActivityIndex) in item.activities"
                  :key="`activity-${TopicIndex}-${ActivityIndex}`"
                >
                  <td>
                    <div class="mb-1 font-weight-medium">{{ activity.title }}</div>
                  </td>
                  <td>
                    <div class="mb-1 font-weight-medium">{{ getActivityProgress(activity) }}</div>
                  </td>
                  <td>
                    <div class="mb-1 font-weight-medium">{{ activity.activity_point }}</div>
                  </td>
                  <td>
                    <div class="mb-1 font-weight-medium">
                      {{ activity.complete_at | datetime }}
                    </div>
                  </td>
                  <td>
                    <div class="mb-1 font-weight-medium">
                      {{ activity.complete_duration }}
                    </div>
                  </td>
                  <td>
                    <div class="mb-1 font-weight-normal caption d-flex justify-center align-center">
                      <div 
                        class="mr-2"
                        style="
                          padding: 2px 5px;
                          color: #1565C0;
                          background: #E3F2FD">
                        {{ getActivityStatus(activity) }}
                      </div>
                      <div @click="handleDetail(activity)" style="color: #2196F3; cursor: pointer">
                        View
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>

        </div>
      </v-col>

      <div v-if="detailPackage.weeks.length < 1" class="d-flex justify-center align-center mt-5">
        <img :src="require('@/assets/img/no_data.png')" alt="No Data">
        <div class="text-h6">
          Data aktifitas tidak tersedia
        </div>
      </div>

      <progress-detail-modal
        v-model="dialog.showDetailModal"
        :items="itemDetail && Array.isArray(itemDetail.gameplay) ? itemDetail.gameplay : []"
      />

      <v-dialog v-model="dialog.showDeleteModal" width="500">
        <v-card outlined class="pa-10 rounded-lg">
          <v-col cols="12" class="text-center font-weight-medium" style="font-size: 30px;">
            Are you sure to delete this progress?
          </v-col>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              class="mr-2 text-capitalize"
              color="#F05326"
              elevation="0"
              text
              @click="handleCancelDelete">
              No. I change my mind.
            </v-btn>
            <v-btn 
              class="mr-2 text-capitalize" 
              elevation="0"
              color="#F05326" 
              dark 
              @click="handleConfirmDelete">
              Yes. Sure.
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { get, post, put , destroy } from '@/service/Axios'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
import ProgressDetailModal from '../../../components/progress/progress-detail-modal.vue';
export default {
  components: { Breadcrumbs, Snackbar, ProgressDetailModal },
  data () {
    return {
      itemsBreadcrumbs: [
        {
          text: 'Daftar Users',
          disabled: true,
          href: `/progress`,
        },
        {
          text: 'Detail Progress User',
          disabled: true,
          href: '/progress',
        }
      ],
      dialog: {
        showDeleteModal: false,
        showDetailModal: false,
      },
      detail_member: {},
      detailPackage: {},
      detailUser: {},
    }
  },
  computed: {
    // ...mapState('ProgressStore', {
    //   detail: (state) => state.detail,
    //   loading: (state) => state.loading,
    // }),
    // detailUser() {
    //   return this.detail?.user || {};
    // },
  },
  async mounted() {
    this.getListCourse();
    // await this.getDetail(this.$route.params.id);
  },
  methods: {
    ...mapActions('ProgressStore', ['getDetail', 'deleteItem']),
    async getDetailMember() {
      await get(`api/v1/member/detail/${this.$route.params.id}`)
      .then((response) => {
        let res = response.data
        this.detail_member = res.data
      })
    },
    async getListCourse(){
      await get(`api/v1/course/list`, {
        params: {
          user_id: this.$route.params.id
        }
      })
      .then((response) => {
        let res = response.data

        this.detailPackage = res.data.list[0]
        this.detailUser = res.data.list[0].user
        this.getDetailMember()
      })
    },
    getActivityProgress(activity) {
      const gameplay = activity.gameplay;
      const progress = gameplay.reduce(
        (carry, current) => {
          const point = current.point + carry.point;
          const max_point = current.max_point + carry.max_point;
          return { point, max_point };
        },
        { point: 0, max_point: 0 }
      );
      return `${progress.point}/${progress.max_point}`;
    },
    getActivityStatus(activity) {
      if (activity.complete_at) return 'done';
      if (activity.gameplay.some((v) => v.answer_at)) return 'in progress';
      if (activity.gameplay.every((v) => !v.answer_at)) return 'new';
    },
    handleDetail(activity) {
      this.itemDetail = activity;
      this.dialog.showDetailModal = true;
    },
    handleDelete() {
      this.dialog.showDeleteModal = true;
    },
    handleCancelDelete() {
      this.dialog.showDeleteModal = false;
    },
    async handleConfirmDelete() {
      const res = await this.deleteItem(this.detailPackage.id);
      if (!res.error) {
        this.$refs.snackbar.open('#4CAF50', `Progress Deleted Succesfully`);
        this.showDeleteModal = false;
        this.$router.push('/progress');
      }
    },
  }
}
</script>

<style scoped>
.simple-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.simple-table th,
.simple-table td {
  padding: 0.5rem;
  border: solid 1px #00000020;
}
</style>
