<template>
  <v-dialog v-model="model" width="800">
    <v-card>
      <v-card-title>Detail</v-card-title>
      <v-divider />
      <v-col class="px-6">
        <table class="simple-table">
          <thead>
            <tr class="grey lighten-3 text-left">
              <th>Question</th>
              <th>Point</th>
              <th>Correct</th>
              <th>Duration</th>
              <th>Answer at</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.point }}</td>
              <td>
                <v-icon
                  v-if="item.is_correct"
                  small
                  class="success lighten-5 pa-1 rounded-circle"
                  color="success"
                  >mdi-check</v-icon
                >
                <v-icon v-else small class="error lighten-5 pa-1 rounded-circle" color="error"
                  >mdi-close</v-icon
                >
              </td>
              <td>{{ item.answer_at ? item.answer_duration + ' second' : '-' }}</td>
              <td>{{ item.answer_at }}</td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  model: { prop: 'modelData', event: 'change' },
  props: { modelData: { type: [Boolean, String, Number, Object, File] }, items: Array },
  computed: {
    model: {
      get() {
        return this.modelData;
      },
      set(val) {
        this.$emit('change', val);
      },
    },
  },
};
</script>

<style scoped>
.simple-table {
  width: 100%;
  border-collapse: collapse;
}
.simple-table th,
.simple-table td {
  padding: 0.5rem;
  border-bottom: solid 1px #eee;
}
</style>
